<template>
  <div>
    <eden-page-header
      :title="'Service Partners'"
      :subtitle="`${form.organization_name}`"
      :section="'Edit Profile'"
    />
    <service-partner-form :service-partner="form" action="edit" />
  </div>
</template>

<script>
import ServicePartnerForm from "@/components/ServiceOperations/ServicePartners/ServicePartnerForm";
import servicepartners from "@/requests/serviceoperations/servicepartners";

export default {
  name: "ServicePartnerEdit",
  components: {
    ServicePartnerForm,
  },
  data() {
    return {
      form: {
        avatar: "",
        organization_name: "",
        email: "",
        phone_no: "",
        service: "",
        address: "",
        bank: "",
        account_no: "",
      },
    };
  },
  created() {
    this.getServicePartner();
  },
  methods: {
    getServicePartner() {
      servicepartners
        .get(this.$route.params.id)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.partners;

            this.form.avatar = data.avatar;
            this.form.organization_name = data.name;
            this.form.email = data.email;
            this.form.phone_no = data.phone_number;
            this.form.service = data.service_offering.toLowerCase();
            this.form.address = data.address;
          }
        })
        .catch();
    },
  },
};
</script>

<style scoped lang="scss"></style>
